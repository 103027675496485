<template>
	<div class="flex mx-auto mb-2 bg-green-100 border-2 border-green-500 border-dashed rounded-md">
		<div class="p-2">
			Deal:
		</div>
		<div class="flex-1 p-2">
			{{ deal.title }}
		</div>
		<div class="p-2">
			{{ deal.display_price }}
		</div>
	</div>
</template>

<script async>
export default {
	props: {
		deal: {
			type: Object,
			default () {
				return {}
			}
		}
	}
}
</script>
